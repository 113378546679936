import React, { ReactNode, CSSProperties } from 'react';
import styled from 'styled-components';

interface Props {
  isError?: boolean;
}

const AsideContainer = styled.aside<Props>`
  padding: 16px;
  margin-bottom: 24px;
  border-left: 8px solid
    ${({ isError, theme }) =>
      isError ? theme.main.secondary : theme.main.primary};
  background-color: ${({ isError, theme }) =>
    isError ? theme.main.lightSecondary : theme.main.lightPrimary};
  border-radius: 8px;
  text-align: center;
  font-family: 'Epilogue';
  color: var(--color-black-700);
  font-size: var(--font-size-body-large-01);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-md);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    width: 100%;
  }
`;

export interface AsideProps {
  children: ReactNode | ReactNode[];
  style?: CSSProperties;
  isError?: boolean;
}

const defaultStyle = {
  '--border-color': 'var(--color-primary-500)',
  '--background-color': 'var(--color-primary-300)',
};

const errorStyle = {
  '--border-color': 'var(--color-secondary-500)',
  '--background-color': 'var(--color-secondary-300)',
};

const Aside: React.FC<AsideProps> = ({ isError, children, style }) => {
  const styles = isError ? errorStyle : defaultStyle;
  return (
    <AsideContainer isError={isError} style={{ ...style, ...styles }}>
      {children}
    </AsideContainer>
  );
};

export default Aside;
